<template>
  <v-layout class="welcome-page-content" justify-center align-center>
    <v-progress-linear color="secondary" indeterminate></v-progress-linear>
  </v-layout>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool, Cache, DataType } from '@/assets/js/util'

export default {
  components: {

  },
  data: () => ({
    assists: {
      tool: new Tool(),
      cache: new Cache()
    }
  }),
  created () {
    console.log(this.assists.tool.isPC())

    // 避免缓存情况
    this.store.commit('setLoan', {})
    this.$store.commit('setHasQualifiedPrincipal', null)

    if (!this.assists.tool.isPC()) {
      return
    }

    const routeParam = this.$route.path
    console.log(routeParam)
    // 暂存路径，方便域名切换时跳转
    this.$store.commit('setRedirectUrlParameter', routeParam)

    const resource = this.$route.params?.resource
    if (this.assists.tool.isNotEmpty(resource)) {
      let parseResource
      switch (true) {
        case routeParam.includes('returning'):
          parseResource = OnlineApi.parseReturningResource
          break
        case routeParam.includes('complete-information'):
          parseResource = OnlineApi.parseCompleteResource
          break
        default:
          parseResource = OnlineApi.parsePathResource
          break
      }

      const successCallback = (res) => {
        if (routeParam.includes('complete-information')) {
          this.handleNOIAResult(res)
        } else {
          this.successSolution(res)
        }
      }

      const errorCallback = (err) => {
        this.assists.tool.setPopupInformation(err.message)
      }

      parseResource(resource, successCallback, errorCallback)
    }
  },
  methods: {
    handleNOIAResult (res) {
      this.assists.cache.clear()
      this.assists.cache.setUserCredential(res.token)
      this.store.commit('setLoan', { ...this.$store.getters.getLoan, loanNo: res.loanNo, incompleteScriptType: res.incompleteScriptType })
      if (!res.acceptedConsent) {
        this.goToScenarioFive()
        this.$store.commit('setHasQualifiedPrincipal', res.qualifiedPrincipal > 1)
        return
      }
      switch (res.incompleteScriptType) {
        case DataType.NOIA_SITUATION.NOT_SUBMITTED.value:
          this.goToScenarioFour()
          break
        case DataType.NOIA_SITUATION.NO_IBV.value:
          this.goToScenarioSix()
          break
        case DataType.NOIA_SITUATION.NOT_VERIFIED.value:
        case DataType.NOIA_SITUATION.NOT_QUALIFIED.value:
          this.$router.push('/bank-statement-require')
          break
        default:
          break
      }
    },
    successSolution (res) {
      this.$store.commit('setPortfolioId', res.portfolioId)
      this.assists.cache.clear()
      this.assists.cache.setUserCredential(res.token)
      this.store.commit('setLoan', { ...this.$store.getters.getLoan, loanId: res.loanId, loanNo: res.loanNo, loanStatus: res.loanStatus })

      // 先根据大状态来判断
      switch (res.loanStatus) {
        case DataType.LoanStatusEnum.WITHDRAWN.value:
          this.goToScenarioOne()
          break
        case DataType.LoanStatusEnum.PENDING.value:
          this.goToScenarioTwo()
          break
        case DataType.LoanStatusEnum.ORIGINATING.value:
          this.judgeFollowAspects(res)
          break
        default:
          this.goToScenarioNine()
          break
      }
    },
    judgeFollowAspects (result) {
      if (result.subStatus === DataType.LoanSubStatusEnum.NON_VOICE_AGENT_REVIEW.value) {
        this.goToScenarioTen()
        return
      }

      if (result.subStatus !== DataType.LoanSubStatusEnum.CUSTOMER_REVIEW.value && !result.isPaperCheck) {
        this.goToScenarioThree()
        return
      }

      if (result.subStatus === DataType.LoanSubStatusEnum.CUSTOMER_REVIEW.value) {
        if (!result.acceptedConsent) {
          this.goToScenarioFive()
          // 用于判定NVR的情况
          this.$store.commit('setHasQualifiedPrincipal', result.qualifiedPrincipal > 1)
        } else if (result.qualifiedPrincipal > 1) {
          // badListRisk表示黑名单，litigationRisk表示诉讼, 但这里不再进行判断，因为走到计算的页面都会被withdraw
          this.goToScenarioFour()
        } else if (result.qualifiedPrincipal === 1 && !result.calculatedQualifiedPrincipal && !this.$route.path.includes('pending-application')) {
          // qualifiedPrincipal 为0表示没触发过计算，为1表示没计算出来，大于1为计算出的金额数（不排除总数为1的情况）
          // 判断pending-application，可能为blocked状态，触发了计算没算出来也可走guidance
          this.goToScenarioSeven()
        } else if (result.acceptedConsent) {
          this.goToScenarioSix()
        }
      } else if (result.isPaperCheck) {
        // 实际判断走不到这，PaperCheck的情况在这只做暂存
        this.goToScenarioEight()
      }
    },
    goToScenarioOne () {
      // loan的状态为withDraw
      this.$router.push('/notification/301')
    },
    goToScenarioTwo () {
      // loan的状态为Pending
      this.$router.push('/notification/101')
    },
    goToScenarioThree () {
      // loan的状态为Originating 但是小状态不为Customer Review
      this.$router.push('/notification/201')
    },
    goToScenarioFour () {
      // 这笔loan已经有loan amount
      this.$router.push('/disbursement/loan-application')
    },
    goToScenarioFive () {
      // 新建的Loan还没签协议
      this.$router.push('/welcome')
    },
    goToScenarioSix () {
      // 已授权，但是没走之后的流程，包括是黑名单的情况
      // 以及创建了requestCode 但是没授权
      this.$router.push('/guidance')
    },
    goToScenarioSeven () {
      // 走到了计算，但是因为未知的原因并没有Loan amount，跳到提示页，并让其重走guidance
      this.$router.push('/notification/203')
    },
    goToScenarioEight () {
      // loan走的paper check
      this.$router.push('/notification/204')
    },
    goToScenarioNine () {
      this.$router.push('/notification/302')
    },
    goToScenarioTen () {
      this.$router.push('/notification/202')
    }
  }
}
</script>

<style lang="sass" scoped>
.welcome-page-content
  height: 100%
  width: 100%
  background: var(--theme_primary)
  padding: 0 30%
</style>
